<template>
  <v-lazy
    :options="{
      threshold: .5
    }"
  >
    <v-container>
      <ProductDetailHeader :product="product" />
      <ProductDetailContent
        v-if="product && myAssigns"
        ref="content"
        :product="product"
        :my-assigns="myAssigns"
        :options="options"
        @set-option="setProductOption"
        @set-purchase-date="setPurchaseDate"
        @set-alias="setAlias"
        @refresh="getProduct"
      />
    </v-container>
  </v-lazy>
</template>

<script>
  import ProductDetailHeader from './Header'
  import ProductDetailContent from './Content'
  export default {
    name: 'ProductView',
    components: { ProductDetailContent, ProductDetailHeader },
    data () {
      return {
        product: {},
        companies: [],
        options: [],
        myAssigns: [],
      }
    },
    computed: {
      productIdx () {
        return this.$route.params.productIdx
      },
    },
    async mounted () {
      this.getProduct()
      this.getMyAssigns()
    },
    methods: {
      async getProduct () {
        const url = `/product/${this.productIdx}`
        await this.$axios.get(url)
          .then(res => {
            if (res && res.data) {
              this.product = res.data
              this.getOptions()
            }
          })
          .catch(err => {
            console.log(err)
          })
      },
      async getMyAssigns () {
        const url = '/product/assign/my'
        const params = {
          productIdx: this.productIdx,
        }
        await this.$axios.get(url, { params: params }).then(res => {
          if (res && res.data) {
            this.myAssigns = res.data
            console.log('Succeed get my assigns.')
          }
        })
      },
      async getOptions () {
        const url = '/product/options'
        const params = {
          productIdx: this.productIdx,
        }
        await this.$axios.get(url, { params: params }).then(res => {
          if (res && res.data) {
            this.options = res.data
          }
        })
      },
      setProductOption (optionsIdx) {
        this.product.optionsIdx = optionsIdx
      },
      setPurchaseDate (date) {
        const purchaseDate = new Date(date).getTime()
        this.product.purchaseDate = purchaseDate
      },
      setAlias (alias) {
        if (alias) {
          this.product.alias = {
            idx: 0,
          }
          this.product.alias.idx = alias
        } else {
          this.product.alias = null
        }
      },
    },
  }
</script>

<style scoped>

</style>
