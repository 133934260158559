<template>
  <v-row>
    <v-col cols="12">
      <v-subheader class="px-0">
        <span v-if="product && product.productName">
          Product / New Products / {{ product.productName.length > 15 ? product.productName.substring(0, 15) + '..' : product.productName }}
        </span>
      </v-subheader>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ProductDetailHeader',
    props: {
      product: Object,
    },
  }
</script>

<style scoped>

</style>
