<template>
  <v-row v-if="product && myAssigns">
    <v-col cols="12" offset-md="1" md="10">
      <v-row align="center" justify="start">
        <v-col cols="12">
          <div class="text-h4">
            <template v-if="product.month">
              [한달리뷰]
            </template>
            {{ product.productName }}
          </div>
          <div class="product-detail text-subtitle-2" v-html="getRemainDays()" />
        </v-col>
        <v-col cols="12" md="8" offset-md="4">
          <v-select
            v-model="alias"
            label="구매자"
            placeholder="구매자 선택"
            :items="aliasItems"
            hide-details
            filled
            dense
          />
        </v-col>
        <v-col cols="12" md="8" offset-md="4">
          <v-select
            v-model="selectedOptionIdx"
            label="옵션"
            placeholder="옵션선택"
            :items="options"
            hide-details
            filled
            dense
            no-data-text="해당 상품은 품절상태입니다."
          />
        </v-col>
        <v-col cols="12" md="8" offset-md="4" class="text-center">
          <v-lazy v-if="showPurchaseDate">
            <div>
              <p class="text-left mb-1">
                <v-icon class="mr-1 text-subtitle-1" :style="{ top: '-1px' }">
                  mdi-calendar-clock-outline
                </v-icon>
                구매예정일
                <v-chip v-if="purchaseDate" class="ml-2" small>
                  {{ purchaseDate }}
                </v-chip>
              </p>
              <v-divider width="100%" class="mb-2" />
              <v-date-picker
                v-if="selectedOption"
                v-model="purchaseDate"
                full-width
                :min="selectedOption.strStartDate"
                :max="selectedOption.strEndDate"
                locale="ko"
                no-title
                scrollable
              />
            </div>
          </v-lazy>
        </v-col>
        <v-col cols="12">
          <div class="text-right text-subtitle-2">
            <template v-if="stock && stock > 0">
              잔여수량 <span class="font-weight-bold">{{ stock }}</span>개
            </template>
            <template v-else-if="stock === 0">
              품절
            </template>
            <template v-else>
              옵션을 선택해주세요.
            </template>
          </div>
          <div class="text-right text-h5">
            ￦ {{ price }}
          </div>
          <div class="text-right text-subtitle-2 blue-grey--text">
            배송비 {{ deliveryFee }}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex py-5">
          <h4>
            상품 설명
          </h4>
          <v-spacer />
        </v-col>
      </v-row>
      <v-divider class="my-3" />
      <v-row>
        <v-col cols="12">
          <v-simple-table>
            <template #default>
              <tbody>
                <tr>
                  <td class="w-25 text-truncate text-md-body-2">
                    업체
                  </td>
                  <td class="text-truncate text-md-body-2">
                    {{ product.company ? product.company.companyName : '정보없음' }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-divider class="my-3" />
      <div class="overflow-hidden" :style="{ height: contentHeight }">
        <div class="product-content px-3 text-body-2" v-html="product.productDetail" />
        <v-row class="mx-0">
          <v-col cols="12" class="px-0">
            <v-btn
              v-if="product.totalStock <= 0"
              large
              width="100%"
              disabled
            >
              품절
            </v-btn>
            <v-btn
              v-if="product.valid === 'sale'"
              x-large
              color="green"
              class="white--text"
              :disabled="!selectedOption"
              :loading="isProcessing"
              width="100%"
              @click="register"
            >
              <template v-if="selectedOption">
                상품신청
              </template>
              <template v-else>
                옵션을 선택해주세요
              </template>
            </v-btn>
            <v-btn
              v-else-if="product.valid === 'wait'"
              large
              width="100%"
              disabled
            >
              신청대기
            </v-btn>
            <v-btn
              v-else-if="product.valid === 'end'"
              large
              width="100%"
              disabled
            >
              신청종료
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-col>
    <v-col cols="12" offset-md="1" md="10">
      <v-carousel
        :continuous="true"
        :show-arrows="true"
        hide-delimiter-background
        :show-arrows-on-hover="true"
        delimiter-icon="mdi-minus"
      >
        <v-carousel-item
          v-for="(image, i) in images"
          :key="'image-' + i"
          class="carousel-image"
        >
          <v-img
            :src="image"
            max-width="100%"
            width="100%"
            contain
            class="rounded-5"
          />
        </v-carousel-item>
      </v-carousel>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'ProductDetailContent',
    props: {
      product: {},
      params: {},
      myAssigns: [],
      options: [],
    },
    data () {
      return {
        carouselHeight: 'initial',
        showProductDetail: true,
        contentHeight: 'initial',
        selectedOptionIdx: null,
        selectedOption: null,
        purchaseDate: null,
        showPurchaseDate: false,
        showPurchaseForm: false,
        aliases: null,
        alias: null,
        isProcessing: false,
      }
    },
    computed: {
      stock () {
        if (this.selectedOption) {
          return this.selectedOption.optionStock
        } else {
          return this.product.totalStock ? this.product.totalStock : null
        }
      },
      price () {
        return this.selectedOption ? this.selectedOption.optionPrice.toLocaleString() : ''
      },
      aliasItems () {
        const items = []
        const assigns = this.myAssigns
        if (assigns && assigns.length > 0) {
          for (const assign of assigns) {
            if (!assign.alias) {
              const none = {
                text: this.$store.state.user.user.id + '(' + this.$store.state.user.user.username + ')',
                value: null,
              }
              items.push(none)
            } else {
              if (this.aliases) {
                for (const alias of this.aliases) {
                  if (assign.alias.idx === alias.idx) {
                    items.push(alias)
                  }
                }
              }
            }
          }
          if (items.length > 0) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.alias = items[0].value
          }
        }
        return items
      },
      baseUrl () {
        const url = this.api
        return url
      },
      mobile () {
        return this.$isMobile
      },
      images () {
        const imageList = []
        if (this.product) {
          if (this.product.productPhoto1) {
            imageList.push(this.product.productPhoto1)
          }
          if (this.product.productPhoto2) {
            imageList.push(this.product.productPhoto2)
          }
          if (this.product.productPhoto3) {
            imageList.push(this.product.productPhoto3)
          }
          if (this.product.productPhoto4) {
            imageList.push(this.product.productPhoto4)
          }
          if (this.product.productPhoto5) {
            imageList.push(this.product.productPhoto5)
          }
        }
        return imageList
      },
      deliveryFee () {
        const fee = this.product.deliveryFee
        const criteria = this.product.deliveryFeeCriteria
        let delivery
        if (!fee) {
          delivery = '무료배송'
        } else {
          delivery = fee.toLocaleString() + '원'
          if (criteria) {
            delivery += ' (' + criteria.toLocaleString() + '원 이상 구매 시 무료' + ')'
          }
        }
        return delivery
      },
    },
    watch: {
      selectedOptionIdx () {
        for (const element of this.options) {
          const item = element
          if (item.idx === this.selectedOptionIdx) {
            this.selectedOption = item
            this.$emit('set-option', this.selectedOption.idx)
            break
          }
        }
        this.purchaseDate = this.$parseDate(this.selectedOption.startDate)
        this.showPurchaseDate = true
      },
      purchaseDate () {
        this.$emit('set-purchase-date', this.purchaseDate)
      },
      alias () {
        this.$emit('set-alias', this.alias)
      },
    },
    beforeMount () {
      this.getAlias()
    },
    methods: {
      closePurchaseForm () {
        this.showPurchaseForm = false
        this.purchaseDate = null
      },
      parseDate (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY년 MM월 DD일')
        } else {
          return date
        }
      },
      parseDateTime (date) {
        if (!isNaN(date)) {
          const d = new Date()
          d.setTime(date)
          return this.$moment(d).format('YYYY.MM.DD HH:mm')
        } else {
          return date
        }
      },
      closeEdit () {
        this.showEdit = false
      },
      async register () {
        if (this.isProcessing) {
          alert('상품 신청 처리 중입니다.')
          return
        }
        if (!this.selectedOption || !this.selectedOption.idx) {
          alert('옵션을 선택해주세요.')
          return
        }
        if (!this.product.purchaseDate) {
          alert('구매 예정일을 선택해주세요.')
          return
        }
        if (confirm(`신규상품(${this.product.productName})을 신청하시겠습니까?`)) {
          this.isProcessing = true
          const url = '/product/register'
          await this.$axios.post(url, this.product).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              alert('신규상품에 대한 신청이 완료되었습니다.')
              this.$router.go(-1)
            } else {
              alert(res.data.message ? res.data.message : '신규상품 신청에 오류가 발생하였습니다.')
            }
          }).catch(() => {
            alert('신규상품 신청에 오류가 발생하였습니다.')
          }).finally(() => {
            this.isProcessing = false
          })
        }
      },
      async getAlias () {
        const url = '/user/alias'
        await this.$axios.get(url).then(res => {
          if (res && res.data && res.data.length > 0) {
            this.aliases = res.data
          } else {
            this.aliases = null
          }
        })
      },
      async uploadImage (file) {
        if (file) {
          const formData = new FormData()
          formData.append('image', file)
          this.$axios.post(`/article/${this.article.idx}/attachment`, formData).then(res => {
            if (res && res.data && res.data.status === 'SUCCESS') {
              if (this.popup) {
                this.singleRefresh()
              } else {
                this.$emit('refresh')
              }
              this.image = null
            }
          })
        }
      },
      getRemainDays () {
        const today = new Date()
        let msg
        let day = 0
        if (this.product.valid === 'wait') {
          day = this.product.startDate - today.getTime()
          day = day / (1000 * 60 * 60 * 24)
          day = Math.ceil(day)
          msg = `<span class="blue-grey--text">판매 시작까지 <span class="font-weight-bold">${day}일</span> 남음</span>`
        } else if (this.product.valid === 'end') {
          day = today.getTime() - this.product.endDate
          day = day / (1000 * 60 * 60 * 24)
          day = Math.ceil(day)
          msg = `<span class="blue-grey--text">판매 종료가 <span class="red--text font-weight-bold">${day}일</span> 지남</span>`
        } else {
          day = this.product.endDate - today.getTime()
          day = day / (1000 * 60 * 60 * 24)
          day = Math.ceil(day)
          let color
          if (day <= 3) {
            color = 'error--text'
          } else if (day <= 10) {
            color = 'warning--text'
          } else {
            color = 'success--text'
          }
          msg = `<span class="blue-grey--text">판매 종료까지 <span class="font-weight-bold ${color}">${day}일</span> 남음</span>`
        }
        return msg
      },
    },
  }
</script>

<style scoped lang="scss">
  .product-content {
    overflow: hidden;
    * {
      max-height: 100% !important;
    }
    &::v-deep {
      * {
        max-height: 100% !important;
      }
      img {
        width: 100%
      }
    }
  }
  .v-carousel {
    height: initial !important;
    .carousel-image::v-deep {
      .v-responsive {
        height: initial !important;
        .v-responsive__content {
          display: flex;
          justify-content: center;
          align-items: start;
          flex-wrap: wrap;
        }
      }
    }
  }
</style>
